import React from 'react';
import { Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';

export const PageTitle = () => {
    const { stepsList } = useAppSelector((state) => state.merchantOnboarding);

    const currentStep = stepsList.find(
        (step, index) =>
            index > 1 &&
            stepsList[index - 1].isProcessCompleted &&
            !step.isProcessCompleted,
    );

    return (
        <>
            {' '}
            <Text type="h4" as="header" data-testid="landing-page-header">
                {currentStep
                    ? (currentStep.key === 'menuStatus' &&
                          'Your DoorDash Integration is in Progress') ||
                      (currentStep.key === 'is_online_ordering_menu_live' &&
                          'Activate DoorDash to Receive Orders')
                    : "Let's get started with DoorDash!"}
            </Text>
            <Text
                style={{ fontSize: '1rem' }}
                data-testid="landing-page-description"
            >
                Sync DoorDash orders with your Point of Sale and manage all of
                your orders from one device.
            </Text>
        </>
    );
};
