import React from 'react';
import { Hyperlink, Icon } from 'spoton-lib';

import styles from './LinkWithIcon.module.scss';

interface ILinkWithIcon {
    linkText: string;
    onClick: () => void;
}

export function LinkWithIcon({ linkText, onClick }: ILinkWithIcon) {
    return (
        <Hyperlink
            className={styles.LinkWithIcon_link}
            target="_blank"
            color="#1C5DCD"
            onClick={onClick}
        >
            {linkText}
            <Icon size={18} name="ExternalLinkIcon" />
        </Hyperlink>
    );
}
