import React from 'react';
import {
    Button,
    Card,
    Col,
    Hyperlink,
    Icon,
    IconButton,
    Row,
    Switch,
    Text,
} from 'spoton-lib';
import { isFulfilled } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

import styles from './PostActivationPage.module.scss';
import { useAppDispatch, useAppSelector } from 'api/store';
import {
    getStoreIntegrationStatus,
    storePause,
    removeSmartButtonModal,
} from 'features/merchant_onboarding/MerchantOnboarding.slice';
import { ExternalLinkInfo, SettingInfo } from './PostActivationPage.types';
import { URL } from 'utils/constants';
import {
    ExternalLinkButton,
    getBusinessHoursUrl,
    ReusableBanner,
    RoundedButton,
    Toast,
} from 'features/common';
import {
    ConnectionIcon,
    DoorDashLogo,
    OrderNowImage,
    SpotOnLogo,
} from 'features/merchant_onboarding/assets';

interface IPostActivationPage {
    onClickContactSupportLink: () => void;
}

export function PostActivationPage({
    onClickContactSupportLink,
}: IPostActivationPage) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {
        businessLocationId,
        isOrderActive,
        loading,
        providerStoreName,
        providerAddress,
        selectedDdStoreDetails,
        ddStoreId,
        isSmartButtonModelClosed,
        isExpressMerchant,
        isDdDeactivated,
        deactivationReason,
    } = useAppSelector((state) => state.merchantOnboarding);

    const { menus } = useAppSelector((state) => state.menus);

    const doorDashAddress = selectedDdStoreDetails?.address
        ? selectedDdStoreDetails.address.split(',')
        : [];

    const externalLinkInfo: ExternalLinkInfo[] = [
        {
            linkText: 'Your Storefront',
            urlLink: ddStoreId
                ? `${URL.STOREFRONT_URL}${ddStoreId}?hideModal=true`
                : URL.STOREFRONT_URL,
        },
        {
            linkText: 'Your Marketplace Listing',
            urlLink: ddStoreId
                ? `${URL.MARKETPLACE_URL}${ddStoreId}`
                : URL.MARKETPLACE_URL,
        },
    ];

    const settingsInfo: SettingInfo[] = [
        {
            title: 'Business Hours',
            description: 'Set DoorDash hours and holidays',
            icon: 'TimeIcon',
            onClick: () => {
                window.open(
                    getBusinessHoursUrl(Boolean(isExpressMerchant)),
                    '_blank',
                );
            },
            isShow: true,
        },
        {
            title: 'Menus',
            description: 'Select a menu to sync with DoorDash',
            icon: 'MenuRestaurant',
            onClick: () => {
                navigate('./menus');
            },
            isShow: !isExpressMerchant && menus.length > 1,
        },
        {
            title: 'Price Markup',
            description:
                'Update DoorDash Marketplace delivery prices by a percentage',
            icon: 'MoneyIcon',
            onClick: () => {
                navigate('./price-markup');
            },
            isShow: true,
        },
    ];

    const onChangeStorePauseStatus = async () => {
        if (businessLocationId) {
            const response = await dispatch(
                storePause({
                    businessLocationId,
                    is_active: !isOrderActive,
                    reason: isOrderActive
                        ? 'store_self_disabled_in_their_POS_portal'
                        : undefined,
                }),
            );
            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    Toast({
                        content: response.payload.data.message,
                    });
                    await dispatch(
                        getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
        }
    };

    const onCloseSmartButtonModal = async () => {
        if (businessLocationId) {
            const response = await dispatch(
                removeSmartButtonModal({
                    businessLocationId,
                    is_model_closed: true,
                }),
            );

            if (response && isFulfilled(response)) {
                if (response.payload.status === 'success') {
                    await dispatch(
                        getStoreIntegrationStatus({
                            businessLocationId,
                        }),
                    );
                }
            }
        }
    };

    return (
        <>
            <Col
                data-testid="landing-page-steps-completed-container"
                className={styles.PostActivationPage_settingsContainer}
            >
                <Text
                    type="h4"
                    as="header"
                    data-testid="landing-page-header-completed"
                >
                    DoorDash Integration
                </Text>
                {isDdDeactivated ? (
                    <ReusableBanner
                        message={
                            <span
                                className={
                                    styles.PostActivationPage_banner___message
                                }
                            >
                                <Text>
                                    There was a problem with your DoorDash
                                    account. Please check your emails and the
                                    DoorDash merchant portal. If you are unable
                                    to resolve the issue, contact DoorDash
                                    Support at 855-222-8111.
                                </Text>
                                <Text>
                                    <b>Error: {deactivationReason}</b>
                                </Text>
                            </span>
                        }
                        variant="danger"
                        className={styles.PostActivationPage_banner}
                        actions={
                            <ExternalLinkButton
                                onClick={() => {
                                    window.open(URL.DOORDASH_URL);
                                }}
                                linkText="Go to DoorDash portal"
                                variant="danger"
                                className={
                                    styles.PostActivationPage_banner___button
                                }
                                iconSize={18}
                            />
                        }
                    />
                ) : (
                    <>
                        {!isSmartButtonModelClosed && (
                            <Row
                                className={
                                    styles.PostActivationPage_smartBtnInstructionCard
                                }
                            >
                                <Col
                                    className={
                                        styles.PostActivationPage_smartBtnInstructionCard___instructionContainer
                                    }
                                >
                                    <span>
                                        <Text isBold type="sub1">
                                            Help customers find your Storefront
                                        </Text>
                                        <Text
                                            style={{
                                                marginTop: '0.3125rem',
                                                fontSize: '1rem',
                                                lineHeight: '1.5rem',
                                            }}
                                        >
                                            <Hyperlink
                                                color="black"
                                                onClick={() =>
                                                    window.open(
                                                        URL.DOORDASH_ADD_STOREFRONT_LINK_URL,
                                                    )
                                                }
                                            >
                                                {' '}
                                                Add a link{' '}
                                            </Hyperlink>{' '}
                                            and{' '}
                                            <Hyperlink
                                                color="black"
                                                onClick={() =>
                                                    window.open(
                                                        URL.DOORDASH_SMART_BUTTON_ARTICLE_URL,
                                                    )
                                                }
                                            >
                                                {' '}
                                                smart button{' '}
                                            </Hyperlink>{' '}
                                            to your website. Or book a call with
                                            DoorDash for help.
                                        </Text>
                                    </span>
                                    <span
                                        className={
                                            styles.PostActivationPage_smartBtnInstructionCard___actionContainer
                                        }
                                    >
                                        <RoundedButton
                                            buttonText="Book a call"
                                            onClick={() =>
                                                window.open(
                                                    URL.DOORDASH_BOOK_CALL_URL,
                                                )
                                            }
                                        />
                                    </span>
                                </Col>
                                <Col
                                    className={
                                        styles.PostActivationPage_smartBtnInstructionCard___orderNowImageContainer
                                    }
                                >
                                    <IconButton
                                        alt="Close Icon"
                                        name="CloseIcon"
                                        size={30}
                                        onClick={onCloseSmartButtonModal}
                                        className={
                                            styles.PostActivationPage_smartBtnInstructionCard___closeIcon
                                        }
                                    />
                                    <OrderNowImage />
                                </Col>
                            </Row>
                        )}
                        <Card className={styles.PostActivationPage_settingCard}>
                            <Row>
                                <Col>
                                    <Text
                                        type="h5"
                                        isBold
                                        data-testid="landing-page-sub-header"
                                    >
                                        Accept orders from DoorDash
                                    </Text>
                                </Col>
                                <Col xs="content">
                                    {loading ? (
                                        <Button
                                            style={{
                                                minHeight: 'fit-content',
                                            }}
                                            variant="tertiary"
                                            isLoading={loading}
                                        />
                                    ) : (
                                        <Switch
                                            checked={isOrderActive}
                                            onChange={onChangeStorePauseStatus}
                                            data-testid="store-active-deactive-btn"
                                        />
                                    )}
                                </Col>
                            </Row>
                            {isOrderActive ? (
                                <Text
                                    type="sub1"
                                    fontSize="1rem"
                                    data-testid="landing-page-info"
                                >
                                    You are receiving DoorDash orders in SpotOn
                                    now. Any menu changes will sync
                                    automatically.
                                </Text>
                            ) : (
                                <ul
                                    style={{
                                        paddingInlineStart: '1.5625rem',
                                        margin: '0px',
                                    }}
                                >
                                    <li>Your DoorDash orders are paused.</li>
                                    <li>
                                        Your DoorDash store will be temporarily
                                        hidden.
                                    </li>
                                    <li>You can resume at any time.</li>
                                </ul>
                            )}
                        </Card>
                    </>
                )}
                <span className={styles.PostActivationPage_quickLinkSection}>
                    <Text type="h5">Quick Links</Text>
                    <span className={styles.PostActivationPage_linksContainer}>
                        {externalLinkInfo.map((externalLink, index) => (
                            <ExternalLinkButton
                                key={index}
                                linkText={externalLink.linkText}
                                onClick={() => {
                                    window.open(externalLink.urlLink);
                                }}
                            />
                        ))}
                    </span>
                </span>
                <span className={styles.PostActivationPage_settingsInfoSection}>
                    <Text type="h5">Settings</Text>
                    <span
                        className={
                            styles.PostActivationPage_settingsInfoContainer
                        }
                    >
                        {settingsInfo.map(
                            (setting, index) =>
                                setting.isShow && (
                                    <Card
                                        key={index}
                                        className={
                                            styles.PostActivationPage_settingInfo
                                        }
                                        onClick={setting.onClick}
                                    >
                                        <span
                                            className={
                                                styles.PostActivationPage_settingInfoCardHeader
                                            }
                                        >
                                            {typeof setting.icon ===
                                            'string' ? (
                                                <Icon
                                                    color="#7D89A3"
                                                    name={setting.icon}
                                                    size={30}
                                                />
                                            ) : (
                                                setting.icon
                                            )}
                                            <Text type="h6">
                                                {setting.title}
                                            </Text>
                                        </span>

                                        <Text
                                            style={{
                                                padding:
                                                    '0.125rem 0 0 3.375rem',
                                            }}
                                        >
                                            {setting.description}
                                        </Text>
                                    </Card>
                                ),
                        )}
                    </span>
                </span>
            </Col>
            <Col
                xs="content"
                className={styles.PostActivationPage_locationSyncInfoContainer}
            >
                <Text type="h5">Locations Synced</Text>
                <span className={styles.PostActivationPage_locationSyncSection}>
                    <span className={styles.PostActivationPage_locationInfo}>
                        <span
                            className={styles.PostActivationPage_locationTile}
                        >
                            <SpotOnLogo />
                            <span
                                className={
                                    styles.PostActivationPage_locationNAMEAddressContainer
                                }
                            >
                                {providerStoreName && (
                                    <Text type="h6">{providerStoreName}</Text>
                                )}
                                {providerAddress && (
                                    <span>
                                        <Text>{`${providerAddress.street_address},`}</Text>
                                        <Text>{`${providerAddress.city}, ${providerAddress.state} ${providerAddress.zip}, ${providerAddress.country}`}</Text>
                                    </span>
                                )}
                            </span>
                            {businessLocationId && (
                                <span>
                                    <Text>Business Location ID:</Text>
                                    <Text>{businessLocationId}</Text>
                                </span>
                            )}
                        </span>

                        <ConnectionIcon />

                        <span
                            className={styles.PostActivationPage_locationTile}
                        >
                            <DoorDashLogo />
                            {selectedDdStoreDetails && (
                                <span
                                    className={
                                        styles.PostActivationPage_locationNAMEAddressContainer
                                    }
                                >
                                    <Text type="h6">
                                        {selectedDdStoreDetails.name}
                                    </Text>
                                    {doorDashAddress.length > 0 && (
                                        <span>
                                            <Text>{doorDashAddress[0]},</Text>
                                            <Text>{`${doorDashAddress.slice(1).join(', ')}`}</Text>
                                        </span>
                                    )}
                                </span>
                            )}
                            {ddStoreId && <Text>Store ID: {ddStoreId}</Text>}
                        </span>
                    </span>
                    <Text
                        className={styles.ContactInfo}
                        data-testid="contact-info"
                    >
                        To select a different location or permanently deactivate
                        integration please contact DoorDash support at
                        <Hyperlink
                            href="tel:855 222 8111"
                            className={`${styles.ContactInfo_link} ${styles.ContactInfo_numberLink}`}
                            data-testid="contact-number"
                        >
                            (855) 222-8111
                        </Hyperlink>
                        or through their
                        <Hyperlink
                            onClick={onClickContactSupportLink}
                            className={styles.ContactInfo_link}
                            data-testid="contact-support-link"
                        >
                            merchant support portal
                        </Hyperlink>
                    </Text>
                </span>
            </Col>
        </>
    );
}
