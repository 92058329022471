import React from 'react';
import { Text } from 'spoton-lib';

import { getConfigVar, ReusableModal } from 'features/common';

interface IConnectDoordashModal {
    isConnectDoordashModalOpen: boolean;
    setIsConnectDoordashModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsDdAccountCreateModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

export function ConnectDoordashModal(props: IConnectDoordashModal) {
    const {
        isConnectDoordashModalOpen,
        setIsConnectDoordashModalOpen,
        setIsDdAccountCreateModalOpen,
    } = props;

    const onConfirm = () => {
        window.location.href = getConfigVar('REACT_APP_DOORDASH_SIGNIN_URL');
    };

    return (
        <ReusableModal
            title="Do you have a DoorDash account?"
            isOpen={isConnectDoordashModalOpen}
            onCancel={() => setIsConnectDoordashModalOpen(false)}
            showCancelBtn={false}
            confirmText="Yes, I have an account"
            onConfirm={onConfirm}
            secondaryConfirmButtonText="No, sign up now"
            onClickSecondaryConfirmButton={() => {
                setIsConnectDoordashModalOpen(false);
                setIsDdAccountCreateModalOpen(true);
            }}
        >
            <Text>
                You’ll need a DoorDash account to continue. If you don’t have
                one, you can sign up now.
            </Text>
        </ReusableModal>
    );
}
