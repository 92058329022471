import React from 'react';
import { Hyperlink, Text } from 'spoton-lib';

import { useAppSelector } from 'api/store';
import { MenuModalTitle } from '../../MerchantOnboarding.utility';
import { ReusableModal } from 'features/common';
import { URL } from 'utils/constants';
import {
    MenuStatus,
    OnboardingStatus as OnboardingStatusEnum,
} from 'features/merchant_onboarding/MerchantOnboarding.types';
import styles from './OnboardingStatus.module.scss';
import { FailBadge, Loader } from 'features/merchant_onboarding/assets';

interface IOnboardingStatus {
    isOnboardingStatusModalOpen: boolean;
    setIsOnboardingStatusModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsActivationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onClickTryAgainBtn: () => void;
}

export function OnboardingStatus(props: IOnboardingStatus) {
    const {
        isOnboardingStatusModalOpen,
        setIsOnboardingStatusModalOpen,
        onClickTryAgainBtn,
    } = props;

    const { menuStatus, onboardingStatus } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const content = (status: MenuStatus | undefined) => {
        switch (status) {
            case MenuStatus.FAILED:
                return (
                    <>
                        <span>
                            <FailBadge />
                        </span>
                        <Text>
                            We were unable to sync your menu. Please try again
                            later. If the error persists
                            <Hyperlink
                                style={{
                                    fontSize: '1rem',
                                    color: '#000000',
                                    fontWeight: '500',
                                }}
                                href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                                target="_blank"
                            >
                                contact DoorDash support.
                            </Hyperlink>
                        </Text>
                    </>
                );
            default:
                return (
                    <>
                        <span>
                            <Loader />
                        </span>
                        <Text style={{ paddingBottom: '1.5rem' }}>
                            This may take a few minutes. If you’re waiting for
                            more than 10 minutes,
                            <Hyperlink
                                style={{
                                    fontSize: '1rem',
                                    color: '#000000',
                                    display: 'inline',
                                }}
                                href={URL.DOORDASH_CONTACT_SUPPORT_URL}
                                target="_blank"
                            >
                                contact DoorDash support
                            </Hyperlink>
                            .
                        </Text>
                    </>
                );
        }
    };

    const onConfirm = (status: MenuStatus | undefined) => {
        if (status === MenuStatus.FAILED) {
            onClickTryAgainBtn();
        }
    };

    return (
        <ReusableModal
            isOpen={isOnboardingStatusModalOpen}
            title={MenuModalTitle(menuStatus?.status)}
            onCancel={() => setIsOnboardingStatusModalOpen(false)}
            onConfirm={() => onConfirm(menuStatus?.status)}
            confirmText={
                menuStatus?.status === MenuStatus.FAILED
                    ? 'Try again'
                    : 'Continue'
            }
            showCancelBtn={
                onboardingStatus === OnboardingStatusEnum.MENU_REQUESTED
                    ? false
                    : true
            }
            showFooter={
                menuStatus?.status === MenuStatus.FAILED &&
                onboardingStatus === 'MENU_BLOCK'
            }
            gap="1.625rem"
            className={styles.OnboardingStatus_modal}
            contentClassName={styles.OnboardingStatus_modal___content}
        >
            {content(menuStatus?.status)}
        </ReusableModal>
    );
}
