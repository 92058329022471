// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnrollOnlineOrderingModal_EnrollOnlineOrderingModal__Fn2G3{max-width:32.5rem}.EnrollOnlineOrderingModal_EnrollOnlineOrderingModal_content__ywxMD{padding-left:0 !important}`, "",{"version":3,"sources":["webpack://./src/features/merchant_onboarding/components/enroll_online_ordering_modal/EnrollOnlineOrderingModal.module.scss"],"names":[],"mappings":"AAAA,4DACI,iBAAA,CAEA,oEACI,yBAAA","sourcesContent":[".EnrollOnlineOrderingModal {\n    max-width: 32.5rem;\n\n    &_content {\n        padding-left: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EnrollOnlineOrderingModal": `EnrollOnlineOrderingModal_EnrollOnlineOrderingModal__Fn2G3`,
	"EnrollOnlineOrderingModal_content": `EnrollOnlineOrderingModal_EnrollOnlineOrderingModal_content__ywxMD`
};
export default ___CSS_LOADER_EXPORT___;
