import React from 'react';
import { Button, Icon, Text } from 'spoton-lib';

import styles from './ExternalLinkButton.module.scss';

interface IExternalLinkButton {
    onClick: () => void;
    linkText: string;
    variant?: 'success' | 'warning' | 'danger' | 'informative';
    className?: string;
    iconSize?: number;
}

export function ExternalLinkButton({
    onClick,
    linkText,
    variant = 'informative',
    className = '',
    iconSize = 24,
}: IExternalLinkButton) {
    return (
        <Button
            className={`${styles.ExternalLinkButton_button} ${styles[`ExternalLinkButton_${variant}Button`] ?? ''} ${className}`}
            onClick={onClick}
        >
            <Text type="sub2">{linkText}</Text>
            <Icon size={iconSize} name="ExternalLinkIcon" />
        </Button>
    );
}
