import React, { Dispatch, SetStateAction, useState } from 'react';

import { ReusableModal } from 'features/common';
import { Menus } from './Menus.component';
import styles from './Menus.module.scss';
import { useAppSelector } from 'api/store';

interface IPriceMarkupModal {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setIsConnectDoordashModalOpen: Dispatch<SetStateAction<boolean>>;
    isButtonDisable: boolean;
}

export function MenusModal({
    isOpen,
    setIsOpen,
    setIsConnectDoordashModalOpen,
    isButtonDisable,
}: IPriceMarkupModal) {
    const { loading } = useAppSelector((state) => state.markupPrice);

    const [clickedButton, setClickedButton] = useState<string>('');

    return (
        <ReusableModal
            isOpen={isOpen}
            title="Choose your SpotOn menu for DoorDash"
            onCancel={() => {
                setIsOpen(false);
                setClickedButton('');
            }}
            isConfirmBtnLoading={loading}
            onConfirm={() => setClickedButton('Continue')}
            secondaryConfirmButtonText="Save & Close"
            onClickSecondaryConfirmButton={() => setClickedButton('Save')}
            headerClassName={styles.MenuModalHeader}
            contentClassName={styles.MenuModalContent}
            isFooterBtnsDisabled={isButtonDisable}
            showCancelBtn={false}
            className={styles.Menus_modal}
        >
            <Menus
                isModal={true}
                setIsOpen={setIsOpen}
                clickedButton={clickedButton}
                setClickedButton={setClickedButton}
                setIsConnectDoordashModalOpen={setIsConnectDoordashModalOpen}
            />
        </ReusableModal>
    );
}
