import React from 'react';
import { Banner } from 'spoton-lib';

import Styles from './ReusableBanner.module.scss';

interface IReusableBanner {
    message: React.ReactNode;
    title?: string;
    variant?: 'success' | 'warning' | 'danger' | 'informative';
    className?: string;
    actions?: React.ReactNode;
}

export function ReusableBanner(props: IReusableBanner) {
    const {
        message,
        title,
        variant = 'informative',
        className = '',
        actions,
    } = props;

    return (
        <Banner
            message={message}
            className={`${Styles[`ReusableBanner_${variant}Banner`] ?? ''} ${Styles.ReusableBanner ?? ''} ${className}`}
            title={title}
            variant={variant}
            actions={actions}
        />
    );
}
