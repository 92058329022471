import React from 'react';
import { Text } from 'spoton-lib';

import { ReusableModal, Toast } from 'features/common';
import { URL } from 'utils/constants';

interface ISuccessModal {
    isSuccessModalOpen: boolean;
    setIsSuccessModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SuccessModal(props: ISuccessModal) {
    const { isSuccessModalOpen, setIsSuccessModalOpen } = props;

    const onClickScheduleCallButton = () => {
        window.open(URL.DOORDASH_BOOK_CALL_URL);
        setIsSuccessModalOpen(false);
        Toast({
            content: "You've completed DoorDash integration setup!",
        });
    };
    return (
        <ReusableModal
            isOpen={isSuccessModalOpen}
            title="Add Online Ordering to your website"
            onCancel={() => {
                setIsSuccessModalOpen(false);
                Toast({
                    content: "You've completed DoorDash integration setup!",
                });
            }}
            showCancelBtn={false}
            confirmText="Schedule call"
            confirmBtnIcon="ExternalLinkIcon"
            onConfirm={onClickScheduleCallButton}
        >
            <Text>
                Schedule a call with DoorDash to add your Online Ordering link
                to your website and Google Business Profile so customers can
                start placing commission-free orders.
            </Text>
        </ReusableModal>
    );
}
