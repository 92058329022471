import React from 'react';
import { Text } from 'spoton-lib';

import { ReusableModal } from 'features/common';
import { FailBadge } from 'features/merchant_onboarding/assets';
import styles from './OpenStoreErrorModal.module.scss';

interface IOpenStoreErrorModal {
    isOpenStoreErrorModalOpen: boolean;
    setIsOpenStoreErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsActivationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function OpenStoreErrorModal(props: IOpenStoreErrorModal) {
    const {
        isOpenStoreErrorModalOpen,
        setIsOpenStoreErrorModalOpen,
        setIsActivationModalOpen,
    } = props;
    return (
        <ReusableModal
            title="Unable to open your store"
            isOpen={isOpenStoreErrorModalOpen}
            onCancel={() => {
                setIsOpenStoreErrorModalOpen(false);
            }}
            confirmText="Try again"
            onConfirm={() => {
                setIsOpenStoreErrorModalOpen(false);
                setIsActivationModalOpen(true);
            }}
            gap="1.625rem"
            contentClassName={styles.OpenStoreErrorModal_content}
        >
            <span>
                <FailBadge />
            </span>
            <Text>
                Please try again. If the problem continues, contact DoorDash
                support at 855-222-8111.{' '}
            </Text>
        </ReusableModal>
    );
}
