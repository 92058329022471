import { showToast } from 'spoton-lib';

import styles from './Toast.module.scss';

interface IToast {
    content: string;
    position?:
        | 'top-right'
        | 'top-center'
        | 'top-left'
        | 'bottom-right'
        | 'bottom-center'
        | 'bottom-left';
    variant?: 'success' | 'warning' | 'danger' | 'informative';
}

export function Toast({
    content,
    position = 'top-center',
    variant = 'success',
}: IToast) {
    showToast({
        content,
        containerId: 'toast-container',
        position: variant === 'success' ? 'bottom-center' : position,
        variant,
        className: `${styles.Toast} ${styles[`Toast___${variant}`]}`,
    });
}
