import React, { useEffect, useState } from 'react';

import { LinkWithIcon, ReusableModal } from 'features/common';
import styles from './EnrollOnlineOrderingModal.module.scss';
import { URL } from 'utils/constants';
import { useAppSelector } from 'api/store';

interface IEnrollOnlineOrderingModal {
    isEnrollOnlineOrderingModalOpen: boolean;
    setIsEnrollOnlineOrderingModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsOnlineOrderingCheckModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    onOnlineOrderingMenuIsLive: () => void;
}
export function EnrollOnlineOrderingModal(props: IEnrollOnlineOrderingModal) {
    const {
        isEnrollOnlineOrderingModalOpen,
        setIsEnrollOnlineOrderingModalOpen,
        setIsOnlineOrderingCheckModalOpen,
        onOnlineOrderingMenuIsLive,
    } = props;

    const { loading } = useAppSelector((state) => state.merchantOnboarding);

    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] =
        useState<boolean>(true);

    const onClickDoordashPortalLink = () => {
        window.open(URL.DOORDASH_URL);
        setIsConfirmButtonDisabled(false);
    };

    useEffect(() => {
        if (!isEnrollOnlineOrderingModalOpen) {
            setIsConfirmButtonDisabled(true);
        }
    }, [isEnrollOnlineOrderingModalOpen]);
    return (
        <ReusableModal
            title="Enroll in DoorDash Online Ordering"
            isOpen={isEnrollOnlineOrderingModalOpen}
            onCancel={() => {
                setIsEnrollOnlineOrderingModalOpen(false);
            }}
            showCancelBtn={false}
            confirmText="I enrolled"
            onConfirm={onOnlineOrderingMenuIsLive}
            isConfirmBtnLoading={loading}
            isConfirmBtnDisabled={isConfirmButtonDisabled}
            secondaryConfirmButtonText="Back"
            onClickSecondaryConfirmButton={() => {
                setIsEnrollOnlineOrderingModalOpen(false);
                setIsOnlineOrderingCheckModalOpen(true);
            }}
            className={styles.EnrollOnlineOrderingModal}
            contentClassName={styles.EnrollOnlineOrderingModal_content}
        >
            <ol type="1">
                <li>
                    Log in to the{' '}
                    <LinkWithIcon
                        linkText="DoorDash Merchant Portal"
                        onClick={onClickDoordashPortalLink}
                    />
                </li>
                <li>
                    On the left, click <b>+ Add Solutions.</b>
                </li>
                <li>
                    Scroll to <b>Online Ordering</b> and click{' '}
                    <b>Add Channel.</b>
                </li>
                <li>
                    Confirm Online Ordering is shown under the <b>Channels</b>{' '}
                    section on the left.
                </li>
            </ol>
        </ReusableModal>
    );
}
