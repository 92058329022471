import React, { useEffect, useState } from 'react';
import { Text } from 'spoton-lib';

import { LinkWithIcon, ReusableModal } from 'features/common';
import { useAppSelector } from 'api/store';
import { URL } from 'utils/constants';

interface IOnlineOrderingCheckModal {
    isOnlineOrderingCheckModalOpen: boolean;
    setIsOnlineOrderingCheckModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsEnrollOnlineOrderingModalOpen: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    onOnlineOrderingMenuIsLive: () => void;
}
export function OnlineOrderingCheckModal(props: IOnlineOrderingCheckModal) {
    const {
        isOnlineOrderingCheckModalOpen,
        setIsOnlineOrderingCheckModalOpen,
        setIsEnrollOnlineOrderingModalOpen,
        onOnlineOrderingMenuIsLive,
    } = props;

    const { ddStoreId, loading } = useAppSelector(
        (state) => state.merchantOnboarding,
    );

    const [isFooterBtnsDisabled, setIsFooterBtnsDisabled] =
        useState<boolean>(true);

    const onClickOnlieOrderingLink = () => {
        const link = ddStoreId
            ? `${URL.STOREFRONT_URL}${ddStoreId}?hideModal=true`
            : URL.STOREFRONT_URL;
        window.open(link);
        setIsFooterBtnsDisabled(false);
    };

    useEffect(() => {
        if (!isOnlineOrderingCheckModalOpen) {
            setIsFooterBtnsDisabled(true);
        }
    }, [isOnlineOrderingCheckModalOpen]);

    return (
        <ReusableModal
            title="Check if your Online Ordering menu is live"
            isOpen={isOnlineOrderingCheckModalOpen}
            onCancel={() => {
                setIsOnlineOrderingCheckModalOpen(false);
            }}
            showCancelBtn={false}
            confirmText="My menu is live"
            onConfirm={onOnlineOrderingMenuIsLive}
            isConfirmBtnLoading={loading}
            secondaryConfirmButtonText="I see an error"
            onClickSecondaryConfirmButton={() => {
                setIsOnlineOrderingCheckModalOpen(false);
                setIsEnrollOnlineOrderingModalOpen(true);
            }}
            isFooterBtnsDisabled={isFooterBtnsDisabled}
        >
            <Text>
                Please visit{' '}
                <LinkWithIcon
                    linkText="your Online Ordering page"
                    onClick={onClickOnlieOrderingLink}
                />{' '}
                to see if your menu is live.
            </Text>
        </ReusableModal>
    );
}
